import React from 'react';
import { email } from 'react-admin';
import { Grid, Box } from '@mui/material';

import { ChildFormFields } from 'src/constants';
import { ShsTextInput, ShsPhoneInput, ShsToggle } from 'src/components/common';
import { validate } from 'src/utils';
import { useFormContext } from 'react-hook-form';
import { Theme } from 'src/Theme';

interface VisitorDataProps {
    getSource?: (source: string) => string;
}

const VisitorData: React.FC<VisitorDataProps> = ({ getSource = (data: string) => data }) => {
    return (
        <>
            <Grid container columnSpacing={4} rowSpacing={1} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_LAST_NAME}`)}
                        label="Прізвище"
                        id={`visitor-last-name`}
                        name={`visitor-last-name`}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_FIRST_NAME}`)}
                        label="Ім'я"
                        id={`visitor-first-name`}
                        name={`visitor-first-name`}
                        required
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={6}>
                    <ShsTextInput
                        source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_PATRONYMIC}`)}
                        label="По батькові"
                        id={`visitor-patronymic`}
                        name={`visitor-patronymic`}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={getSource(ChildFormFields.VISITOR_ROLE)}
                        label="Роль представника"
                        id={`visitor-role`}
                        name={`visitor-role`}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShsTextInput
                        source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_TAX_NUMBER}`)}
                        label="Ідентифікаційний номер платника податків (РНОКПП)"
                        validate={validate.taxNumberUA()}
                        inputProps={{ maxLength: 10 }}
                        id={`visitor-tax-number`}
                        name={`visitor-tax-number`}
                        required
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={6}>
                    <ShsPhoneInput
                        source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_PHONE}`)}
                        label="Контактний номер телефону"
                        id={`visitor-phone`}
                        name={`visitor-phone`}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_EMAIL}`)}
                        label="Email"
                        id={`visitor-email`}
                        name={`visitor-email`}
                        validate={email()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShsToggle
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        source={getSource(ChildFormFields.VISITOR_ACTIVE)}
                        label="Активний представник"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default VisitorData;
