import { FC, useEffect, useState } from 'react';
import { TopToolbar, CreateButton } from 'react-admin';
import { Box } from '@mui/material';
import { ShsButton, ShsSelectInput, ShsTitle, ShsToggle, WithRole } from 'src/components/common';
import { shsButtonStyles } from 'src/styles';
import { UserRole } from 'src/auth/types';
import CircleButtonPlus from 'src/assets/CircleButtonPlus';
import { useWatch } from 'react-hook-form';
import axiosApiInstance from 'src/api/axiosConfig';
import { EducationCenterType } from 'src/components/Employees/types';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { palette } from 'src/Theme';

const body = {
    page: 0,
    size: 999,
    sort: {
        properties: ['id'],
        direction: 'ASC'
    }
};

const fetchEducationCenters = async (): Promise<any> => {
    const { data } = await axiosApiInstance.post(`centers`, body);

    return data;
};
export const ScheduleToolbar: FC = () => {
    const viewMode = useWatch({
        name: 'viewMode',
        defaultValue: null
    });

    const { setCalendarData } = useScheduleCalendarContext();

    const [eduCenters, setEduCenters] = useState<EducationCenterType[]>([]);

    useEffect(() => {
        fetchEducationCenters().then((data) => {
            const centers = data.content.filter((x: any) => x.active);
            setEduCenters(centers);
        });
    }, [setEduCenters]);

    const handleOnChange = (e: any) => {
        const center = eduCenters.find((x) => x.id === e.target.value);
        setCalendarData((value) => ({ ...value, educationCenter: center }));
    };

    return (
        <TopToolbar
            sx={{
                width: '100%',
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                marginTop: '20px',
                marginBottom: '12px',
                '.ShsTitle-root': { marginBottom: 0 }
            }}>
            <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={'16px'}>
                <Box sx={{ flex: 1, display: 'flex', gap: '16px', flexDirection: 'column' }}>
                    <ShsTitle title="Розклад" />
                    <ShsToggle
                        label="Режим перегляду"
                        source="viewMode"
                        sx={{
                            '.MuiFormHelperText-root': {
                                display: 'none'
                            },
                            '& .MuiSwitch-root': {
                                background: '#CCCCCE'
                            }
                        }}
                    />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'16px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]}>
                        <Box
                            sx={{
                                '& .text_field__label': {
                                    fontWeight: 400,
                                    fontSize: '11px',
                                    lineHeight: '14px',
                                    color: palette.primary.grayBluish
                                }
                            }}>
                            <ShsSelectInput
                                sx={{
                                    width: '280px',

                                    '.MuiInputBase-root': {
                                        height: '40px',
                                        fontSize: '14px',
                                        '.MuiInputBase-input': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '40px',
                                            paddingBottom: 0,
                                            paddingTop: 0
                                        }
                                    }
                                }}
                                source="center"
                                label="Освітній центр"
                                choices={eduCenters}
                                onChange={handleOnChange}
                                filter=""
                                autoFocus
                                required
                            />
                        </Box>
                    </WithRole>

                    {!viewMode && (
                        <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]}>
                            <Box gap={'16px'} display={'flex'}>
                                <ShsButton label="Скопіювати з попереднього тижня" styleType="outline" />
                                <CreateButton
                                    label="Додати предмет"
                                    variant="contained"
                                    icon={<CircleButtonPlus color="white" />}
                                    sx={shsButtonStyles}
                                />
                            </Box>
                        </WithRole>
                    )}
                </Box>
            </Box>
        </TopToolbar>
    );
};
