import type { GetListParams } from 'react-admin';

export default {
    getList: async (params: GetListParams) => {
        return {
            data: [],
            total: 0
        };
    }
};
