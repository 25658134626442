import React from 'react';
import { UserRole } from 'src/auth/types';

export enum ElementIdParameter {
    EducationCenterId = 'educationCenterId',
    LoggedInUserId = 'loggedInUserId'
}

export type MenuItemType = {
    label: string;
    icon: React.ReactElement;
    navigateTo: string;
    eligibleRoles?: UserRole[];
    // defines which id should be used for '/${id}/show'
    elementId?: ElementIdParameter;
};
