import type { UpdateParams, GetListParams, GetOneParams } from 'react-admin';
import axiosApiInstance, { postRequestCacheConfig } from '../../axiosConfig';
import ApiMocks from 'src/api/mocks';
import { childToAPITransform } from 'src/api/Children/transforms';
import { Resource } from 'src/constants/resources';
import type { Child, ChildVisit } from 'src/components/Children/types';
import { mapEducationLevelToGroupFull } from 'src/utils/children';
import {
    HighSchoolEducationLevels,
    JuniorSchoolEducationLevels,
    PreSchoolEducationLevels
} from 'src/constants/children/form';
import { EducationLevelGroup } from 'src/constants';

export default {
    getList: async (params: GetListParams) => {
        let { keyword, ...filters } = params.filter;
        filters = Object.keys(filters).length ? filters : undefined;
        transformEducationLevelsFilter(filters);

        const pageRequest = {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: params.sort && { properties: [params.sort.field], direction: params.sort.order },
            keyword,
            filters,
            _timestamp: Date.now()
        };

        const response = await axiosApiInstance.post(Resource.CHILDREN, pageRequest, {
            ...postRequestCacheConfig,
            headers: {
                ...postRequestCacheConfig.headers,
                'Cache-Control': 'no-cache'
            }
        });

        // as backend will not transform classes into levels - making it happen on a UI side
        childrenGradeToLevelUpdater(response.data.content);

        return {
            data: response.data.content,
            total: response.data.totalElements
        };
    },

    update: (params: UpdateParams) => {
        const { useMock, method } = params.meta || {};
        if (useMock && ApiMocks[Resource.CHILDREN].update) {
            return Promise.resolve({
                data: { ...ApiMocks[Resource.CHILDREN].getOne, id: params.id }
            });
        }
        const methodUrlPath = method ? `${method.startsWith('/') ? '' : '/'}${method}` : '/update';

        return axiosApiInstance
            .put(`${Resource.CHILDREN}${methodUrlPath}`, childToAPITransform(params.data as Child))
            .then((response) => response);
    },

    getOne: (params: GetOneParams) => {
        return axiosApiInstance.get(`${Resource.CHILDREN}/${params.id}`).then((response) => {
            // as backend will not transform classes into levels - making it happen on a UI side
            response.data.visit.educationLevelGroup = mapEducationLevelToGroupFull(response.data.visit.educationLevel);
            childrenVisitHistoryGradeToLevelUpdater(response.data.visitHistory);

            return response;
        });
    }
};

/**
 * Updates the response, by adding educationLevelGroup property based on educationLevel
 * @param { Child[] } childrenData
 */
const childrenGradeToLevelUpdater = (childrenData: Child[]): void => {
    childrenData?.forEach((child: Child) => {
        child.visit.educationLevelGroup = mapEducationLevelToGroupFull(child.visit.educationLevel);
    });
};

/**
 * Updates the response, by adding educationLevelGroup property based on educationLevel for the visitHistory array
 * @param { ChildVisit[] } visitHistory
 */
const childrenVisitHistoryGradeToLevelUpdater = (visitHistory: ChildVisit[]): void => {
    visitHistory?.forEach((visit: ChildVisit) => {
        visit.educationLevelGroup = mapEducationLevelToGroupFull(visit.educationLevel);
    });
};

/**
 * Transforms educationLevels from key to educationLevel array, as backend awaits values as ["GRADE1, GRADE2"]
 * @param { any } filters
 */
const transformEducationLevelsFilter = (filters: any) => {
    if (!filters.educationLevels) {
        return;
    }

    switch (filters.educationLevels) {
        case EducationLevelGroup.PRESCHOOL:
            filters.educationLevels = PreSchoolEducationLevels;
            break;
        case EducationLevelGroup.JUNIOR_SCHOOL:
            filters.educationLevels = JuniorSchoolEducationLevels;
            break;
        case EducationLevelGroup.HIGH_SCHOOL:
            filters.educationLevels = HighSchoolEducationLevels;
            break;
    }
};
