import { EducationLevelGroup } from 'src/constants';
import {
    EducationLevel,
    EducationLevelGroupData,
    EducationLevelGroupFullName,
    EducationLevelGroupShortName,
    EducationLevelGroupShortNameValues,
    HighSchoolEducationLevels,
    JuniorSchoolEducationLevels,
    PreSchoolEducationLevels
} from 'src/constants/children/form';

/**
 * Determines if a child's education is currently active based on the provided start and end dates.
 * @param {string} startDate - Start date of the education period.
 * @param {string | undefined | null} endDate - Optional end date of the education period.
 *                                       If undefined, the education is considered ongoing.
 * @returns {boolean} - Returns `true` if the current date is within the education period, `false` otherwise.
 */
export const isChildEducationActive = (startDate: string, endDate: string | undefined | null): boolean => {
    const today = new Date();
    const start = new Date(startDate);

    if (endDate) {
        const end = new Date(endDate);
        return start <= today && today <= end;
    } else {
        return start <= today;
    }
};

export const isChildEducationInactive = (endDate: string | undefined | null): boolean => {
    if (!endDate) return false;

    const today = new Date();
    const end = new Date(endDate);

    return today >= end;
};

/**
 * Maps educationLevel into educationLevelGroup
 * @param { EducationLevel | null } educationLevel
 * @returns { EducationLevelGroupShortNameValues | null }
 */
export const mapEducationLevelToGroup = (
    educationLevel: EducationLevel | null
): EducationLevelGroupShortNameValues | null => {
    if (!educationLevel) return null;

    if (HighSchoolEducationLevels.includes(educationLevel)) {
        return EducationLevelGroupShortName.highSchool;
    }

    if (JuniorSchoolEducationLevels.includes(educationLevel)) {
        return EducationLevelGroupShortName.juniorSchool;
    }

    if (PreSchoolEducationLevels.includes(educationLevel)) {
        return EducationLevelGroupShortName.preschool;
    }

    return null;
};

/**
 * Maps educationLevel into educationLevelGroup
 * @param { EducationLevel | null } educationLevel
 * @returns { EducationLevelGroupData | null }
 */
export const mapEducationLevelToGroupFull = (educationLevel: EducationLevel | null): EducationLevelGroupData | null => {
    if (!educationLevel) return null;

    let id: EducationLevelGroup | null = null;
    if (HighSchoolEducationLevels.includes(educationLevel)) {
        id = EducationLevelGroup.HIGH_SCHOOL;
    } else if (JuniorSchoolEducationLevels.includes(educationLevel)) {
        id = EducationLevelGroup.JUNIOR_SCHOOL;
    } else if (PreSchoolEducationLevels.includes(educationLevel)) {
        id = EducationLevelGroup.PRESCHOOL;
    }

    return id
        ? ({
              id,
              shortName: EducationLevelGroupShortName[id],
              fullName: EducationLevelGroupFullName[id]
          } as EducationLevelGroupData)
        : null;
};

/**
 * Maps educationLevelGroupName into EducationLevel[]
 * @param { EducationLevelGroupNameValues | null } educationLevelGroupName
 * @returns { EducationLevel[] | null }
 */
export const mapEducationGroupToLevelArray = (
    educationLevelGroupName: EducationLevelGroupShortNameValues | null
): EducationLevel[] | null => {
    if (!educationLevelGroupName) return null;

    switch (educationLevelGroupName) {
        case EducationLevelGroupShortName.highSchool:
            return HighSchoolEducationLevels;
        case EducationLevelGroupShortName.juniorSchool:
            return JuniorSchoolEducationLevels;
        case EducationLevelGroupShortName.preschool:
            return PreSchoolEducationLevels;
        default:
            return null;
    }
};
