import React, { useEffect } from 'react';
import { TopToolbar as Toolbar, useRecordContext, useRedirect, useCreatePath } from 'react-admin';
import { get } from 'lodash';
import { Box, Stack } from '@mui/material';

import { ChildFormFields, EducationLevelList, ChildGenderList } from 'src/constants';
import { getCurrentAgeInUAFormat, childrenUtils } from 'src/utils';
import EditIcon from 'src/assets/Edit';
import UnknownAvatar from 'src/components/common/UnknownAvatar';
import { ShsButton, StatusBadge, StarLabel } from 'src/components/common';
import { sectionTitleStyle, interFontStyle } from 'src/styles';
import { EducationLevelTableList, NoValuePlaceholder } from 'src/constants/children/form';

interface TopToolbarProps {
    setIsActive: (isActive: boolean) => void;
}

const TopToolbar: React.FC<TopToolbarProps> = ({ setIsActive }) => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const record = useRecordContext();

    const titleName = `${record?.[ChildFormFields.LAST_NAME] || ''} ${record?.[ChildFormFields.FIRST_NAME] || ''} ${
        record?.[ChildFormFields.PATRONYMIC] || ''
    }`;
    const educationLevel = EducationLevelTableList.find(
        (level) => level.id === record?.[ChildFormFields.VISIT]?.[ChildFormFields.EDUCATIONAL_LEVEL]
    );
    const canShowEducationLevel = educationLevel && educationLevel.name !== NoValuePlaceholder;
    const educationGroupLevel = record?.[ChildFormFields.VISIT]?.[ChildFormFields.EDUCATIONAL_GROUP_LEVEL];
    const gender = ChildGenderList.find((gender) => gender.id === record?.[ChildFormFields.GENDER])?.name || '';
    const age = getCurrentAgeInUAFormat(record?.[ChildFormFields.BIRTH_DATE]);
    const educationCenter = record?.[ChildFormFields.VISIT]?.[ChildFormFields.EDUCATION_CENTER_DATA];
    const isActive = childrenUtils.isChildEducationActive(
        get(record, 'visit.servicesAgreementDate'),
        get(record, 'visit.servicesEndDate')
    );

    const handleEditClick = () => {
        redirect(createPath({ resource: 'children', type: 'edit', id: record?.id }));
    };

    useEffect(() => {
        setIsActive(isActive);
    }, [isActive, setIsActive]);

    return (
        <Toolbar>
            <Stack display="flex" flexDirection="row" width={1}>
                <Box mr={3}>
                    <UnknownAvatar
                        firstName={record?.[ChildFormFields.FIRST_NAME] || ''}
                        lastName={record?.[ChildFormFields.LAST_NAME] || ''}
                        size="extra-large"
                    />
                </Box>
                <Stack sx={{ flex: '1 1 100%' }}>
                    <Box sx={sectionTitleStyle}>{titleName}</Box>
                    <Box whiteSpace="normal">
                        <StatusBadge isActive={isActive} sx={{ mr: 3 }} />
                        <StarLabel text={`${gender}, ${age}`} sx={{ mr: 3, mt: 1.5 }} />
                        {canShowEducationLevel && <StarLabel text={educationLevel?.name} sx={{ mt: 1.5 }} />}
                        <StarLabel label="Рівень освіти" text={educationGroupLevel?.fullName} sx={{ mt: 1.5 }} />
                    </Box>
                    <Box sx={{ ...interFontStyle, mt: 1.5 }} whiteSpace="normal">
                        {educationCenter?.name}
                    </Box>
                </Stack>
                <Box>
                    <ShsButton
                        label={isActive ? 'Редагувати' : 'Активувати'}
                        styleType="outline"
                        icon={<EditIcon />}
                        sx={{ height: '40px' }}
                        onClick={handleEditClick}
                    />
                </Box>
            </Stack>
        </Toolbar>
    );
};

export default TopToolbar;
