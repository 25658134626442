import React, { useState } from 'react';
import { Show, TabbedShowLayout, useCreatePath, Loading, useShowContext } from 'react-admin';
import { Container } from '@mui/material';

import { Theme } from 'src/Theme';
import { tabbedContainerStyles } from 'src/styles';
import { ShsReturnButton } from 'src/components/common';
import TopToolbar from './TopToolbar';
import GeneralTab from './GeneralTab';
import SchedulerTab from './SchedulerTab';

const tabbedLayoutStyles = {
    '& .RaTabbedShowLayout-content:has(.show-page-container.scheduler)': {
        padding: 0
    }
};

export const ChildShow: React.FC = () => {
    const { isLoading } = useShowContext();
    const createPath = useCreatePath();
    const [isActive, setIsActive] = useState(false);
    const isSchedulerEnabled = false;

    const getIsActive = (isActive: boolean) => {
        setIsActive(isActive);
    };

    if (isLoading) {
        return <Loading />;
    }
    return (
        <Container maxWidth={false} sx={tabbedContainerStyles}>
            <ShsReturnButton
                to={createPath({ resource: 'children', type: 'list' })}
                label="Назад до списку дітей"
                sx={{ fontSize: Theme.typography.body2.fontSize, color: Theme.palette.primary.grayBluish }}
            />
            <Show
                className="show-page-container"
                queryOptions={{ meta: { useMock: false } }}
                actions={<TopToolbar setIsActive={getIsActive} />}>
                <TabbedShowLayout sx={tabbedLayoutStyles}>
                    <TabbedShowLayout.Tab label="Загальна інформація">
                        <GeneralTab />
                    </TabbedShowLayout.Tab>
                    {isSchedulerEnabled && isActive && (
                        <TabbedShowLayout.Tab label="Розклад учня" path="scheduler">
                            <SchedulerTab />
                        </TabbedShowLayout.Tab>
                    )}
                    <TabbedShowLayout.Tab label="Звітність">
                        <>ChildShow</>
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>
            </Show>
        </Container>
    );
};
