import React, { useEffect, useState, useMemo } from 'react';
import { ArrayInput, FormDataConsumer, SimpleFormIterator } from 'react-admin';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { disableFirstRemoveStyle, formIteratorStyles } from 'src/styles';
import { ChildFormFields } from 'src/constants';
import { ShsButton } from 'src/components/common';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash-can.svg';
import VisitorData from './VisitorData';
import { useFormContext } from 'react-hook-form';
import { Theme } from 'src/Theme';

const VisitorsList: React.FC = () => {
    const { setValue, watch } = useFormContext();
    const visitors = watch(`${ChildFormFields.VISIT}.${ChildFormFields.VISITORS}`, []);
    const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);

    useEffect(() => {
        const subscription = watch((formData, { name, type }) => {
            if (type !== 'change') return;
            if (name?.includes(ChildFormFields.VISITOR_ACTIVE)) {
                const prefix = `${ChildFormFields.VISIT}.${ChildFormFields.VISITORS}.`;
                const suffix = `.${ChildFormFields.VISITOR_ACTIVE}`;

                if (name.startsWith(prefix) && name.endsWith(suffix)) {
                    const indexPart = name.substring(prefix.length, name.length - suffix.length);
                    const currentIndex = parseInt(indexPart);

                    if (currentIndex != null) {
                        const isActive =
                            formData?.[ChildFormFields.VISIT]?.[ChildFormFields.VISITORS]?.[currentIndex]?.[
                                ChildFormFields.VISITOR_ACTIVE
                            ];

                        if (isActive) {
                            const visitorsArray = formData[ChildFormFields.VISIT]?.[ChildFormFields.VISITORS] || [];
                            visitorsArray.forEach((visitor: any, index: number) => {
                                if (index !== currentIndex && visitorsArray[index]?.[ChildFormFields.VISITOR_ACTIVE]) {
                                    setValue(
                                        `${ChildFormFields.VISIT}.${ChildFormFields.VISITORS}.${index}.${ChildFormFields.VISITOR_ACTIVE}`,
                                        false,
                                        { shouldDirty: true, shouldTouch: true }
                                    );
                                }
                            });
                        }
                    }
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, setValue]);

    useEffect(() => {
        const isFormValid = visitors.every(
            (item: any) =>
                item?.parent?.surname &&
                item?.parent?.name &&
                item?.parent?.taxNumber &&
                item?.parent?.phone &&
                item?.role
        );
        setIsAddBtnDisabled(!isFormValid);
    }, [visitors]);

    return (
        <ArrayInput source={`${ChildFormFields.VISIT}.${ChildFormFields.VISITORS}`} label="">
            <SimpleFormIterator
                inline
                getItemLabel={(index) => (
                    <Typography
                        minWidth={'100%'}
                        variant="h5"
                        component="span"
                        mb={1}
                        mt={1}
                        fontWeight="600"
                        color={Theme.palette.primary.midnightBlue}>
                        {`Законний представник дитини - ${index + 1}`}
                    </Typography>
                )}
                className="with-bottom-border"
                disableAdd={visitors.length >= 10}
                addButton={
                    <Box sx={{ mt: 1 }}>
                        <ShsButton
                            disabled={isAddBtnDisabled}
                            styleType="outline-no-border"
                            sx={{ paddingLeft: '0 !important' }}
                            label="Додати представника"
                            icon={<AddCircleOutlineIcon sx={{ fontSize: '24px !important' }} />}
                        />
                    </Box>
                }
                removeButton={<ShsButton styleType="text-danger" sx={{ p: 0, mb: 1 }} label="" icon={<DeleteIcon />} />}
                disableClear
                disableReordering
                sx={{
                    ...disableFirstRemoveStyle,
                    ...formIteratorStyles,
                    '& .RaSimpleFormIterator-line': {
                        flexWrap: 'wrap',
                        flexDirection: 'row'
                    },
                    '& .RaSimpleFormIterator-inline': {
                        maxWidth: '85%'
                    },
                    '& .RaSimpleFormIterator-action': {
                        marginTop: '70px'
                    }
                }}>
                <FormDataConsumer>
                    {({ getSource }) => {
                        return <VisitorData getSource={getSource} />;
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default VisitorsList;
