import { Button, MenuItemLink, MenuItemLinkProps, useAuthProvider, useNotify, useSidebarState } from 'react-admin';
import { uniqueId } from 'lodash';
import { Box } from '@mui/material';
import cn from 'classnames';
import { ElementIdParameter, MenuItemType } from './types';
import { ReactComponent as Logo } from 'src/assets/logo.svg';
import { ReactComponent as ShortLogo } from 'src/assets/short-logo.svg';
import { ReactComponent as EducationCenterIcon } from 'src/assets/education-center.svg';
import { ReactComponent as ReportIcon } from 'src/assets/grid-alt.svg';
import { ReactComponent as CollapseIcon } from 'src/assets/angel-double-left.svg';
import { ReactComponent as ExpandIcon } from 'src/assets/angel-double-right.svg';
import { ReactComponent as ChildrenIcon } from 'src/assets/children.svg';
import { ReactComponent as TeacherIcon } from 'src/assets/teacher.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/calendar.svg';
import { ReactComponent as BrowserTimerIcon } from 'src/assets/browser-timer.svg';
import { ReactComponent as BookIcon } from 'src/assets/book.svg';
import { ReactComponent as ScaleIcon } from 'src/assets/scale.svg';
import { ReactComponent as ServicesIcon } from 'src/assets/ruler-pencil.svg';
import { ReactComponent as InstractionIcon } from 'src/assets/instruction.svg';
import { ReactComponent as FeedbackIcon } from 'src/assets/feedback.svg';
import WithRole from 'src/components/common/WithRole';
import { UserRole, UserType } from 'src/auth/types';
import './index.css';
import { Resource } from 'src/constants';
import {
    AdminGroup,
    AllExceptParentsRolesGroup,
    SpecialTeachersGroup,
    SystemAdminAndSecretaryRolesGroup,
    TeachersGroup
} from 'src/constants/roles';
import {
    EducationCenterOnlyShowRolesGroup,
    EducationCentersListRolesGroup
} from 'src/constants/centers/centers-roles-helper';
import { EmployeeListRolesGroup } from 'src/constants/employee/employee-roles-helper';
import { useEffect, useState } from 'react';

const SHOULD_REPLACE_TO_REAL_DATA: MenuItemType[] = [
    {
        label: 'Звіти',
        icon: <ReportIcon />,
        navigateTo: `/${Resource.REPORTS}`,
        eligibleRoles: [...SystemAdminAndSecretaryRolesGroup, UserRole.ADMIN]
    },
    {
        label: 'Освітні центри',
        icon: <EducationCenterIcon />,
        navigateTo: `/${Resource.CENTERS}`,
        eligibleRoles: EducationCentersListRolesGroup
    },
    {
        label: 'Освітній центр',
        icon: <EducationCenterIcon />,
        navigateTo: `/${Resource.CENTERS}`,
        eligibleRoles: EducationCenterOnlyShowRolesGroup,
        elementId: ElementIdParameter.EducationCenterId
    },
    {
        label: 'Діти',
        icon: <ChildrenIcon />,
        navigateTo: `/${Resource.CHILDREN}`,
        eligibleRoles: [
            ...AdminGroup,
            UserRole.PSYCHOLOGIST,
            ...TeachersGroup,
            ...SpecialTeachersGroup,
            UserRole.PARENT
        ]
    },
    {
        label: 'Працівники',
        icon: <TeacherIcon />,
        navigateTo: `/${Resource.EMPLOYEES}`,
        eligibleRoles: EmployeeListRolesGroup
        // Employees list differentiates according to the user role.
        // UI will not send any additional parameter as backend know how to handle it.
        // (Show full list, employees for education center where the user belongs to)
    },
    {
        label: 'Розклад',
        icon: <CalendarIcon />,
        navigateTo: `/${Resource.SCHEDULE}`,
        eligibleRoles: AllExceptParentsRolesGroup
    },
    // {
    //     label: 'Календарне планування',
    //     icon: <BrowserTimerIcon />,
    //     navigateTo: '/planning',
    //     eligibleRoles: [...AdminGroup, ...TeachersGroup]
    // },
    {
        label: 'Навчальний план',
        icon: <BookIcon />,
        navigateTo: `/${Resource.SUBJECTS}`,
        eligibleRoles: [...AdminGroup, UserRole.PSYCHOLOGIST, ...TeachersGroup]
    },
    {
        label: 'Спеціальні послуги',
        icon: <ServicesIcon />,
        navigateTo: `/${Resource.SPECIAL_SERVICES}`,
        eligibleRoles: [...AdminGroup, ...SpecialTeachersGroup]
    },
    {
        label: 'Законодавство',
        icon: <ScaleIcon />,
        navigateTo: `/${Resource.LAWS}`,
        eligibleRoles: AllExceptParentsRolesGroup
    }
];

const ShsMenuItem = (props: MenuItemLinkProps) => (
    <MenuItemLink
        {...props}
        sx={(theme) => ({
            '&.RaMenuItemLink-active': {
                borderRight: `3px solid ${theme.palette.primary.main}`,
                background: `${theme.palette.primary.dark}`,
                color: `${theme.palette.primary.main}`,
                fill: `${theme.palette.primary.main}`,
                stroke: `${theme.palette.primary.main}`
            },
            ...((typeof props.sx === 'function' ? props.sx(theme) : props.sx || {}) as any)
        })}
    />
);

export const Sidebar = () => {
    const [open, setOpen] = useSidebarState();
    const [profile, setProfile] = useState<UserType>();
    const [educationCenterId, setEducationCenterId] = useState<number>();
    const { getIdentity, getEducationCenterId } = useAuthProvider();

    useEffect(() => {
        getIdentity?.().then((profile) => setProfile(profile as UserType));
    }, [getIdentity]);
    useEffect(() => {
        setEducationCenterId(getEducationCenterId());
    }, [getEducationCenterId]);

    const getRedirectUrl = (navigateTo: string, elementId?: ElementIdParameter): string => {
        let id: number | undefined = undefined;
        if (elementId) {
            id = elementId === ElementIdParameter.LoggedInUserId ? profile?.id : educationCenterId;
        }

        return id ? `${navigateTo}/${id}/show` : navigateTo;
    };

    return (
        <Box
            className="sidebar_container"
            sx={{
                width: open ? 280 : 56,
                transition: (theme) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    })
            }}>
            <div className={cn([!open ? 'short' : '', 'sidebar__logo_container'])}>
                {open ? <Logo /> : <ShortLogo />}
            </div>

            <div className="sidebar_content">
                <div>
                    {SHOULD_REPLACE_TO_REAL_DATA.map(({ label, icon, navigateTo, eligibleRoles, elementId }) => (
                        <WithRole roles={eligibleRoles} key={uniqueId(label)}>
                            <ShsMenuItem
                                key={getRedirectUrl(navigateTo, elementId)}
                                to={getRedirectUrl(navigateTo, elementId)}
                                primaryText={label}
                                leftIcon={icon}
                                state={{ _scrollToTop: true }}
                            />
                        </WithRole>
                    ))}
                    <hr className="sidebar_line" />
                </div>
            </div>

            {/* <ShsMenuItem
                to="/instruction"
                primaryText="Інструкція"
                leftIcon={<InstractionIcon />}
                state={{ _scrollToTop: true }}
            />
            <ShsMenuItem
                to="/feedback"
                primaryText="Зворотній зв'язок"
                leftIcon={<FeedbackIcon />}
                state={{ _scrollToTop: true }}
                sx={{ marginBottom: '40px' }}
            /> */}

            <MenuItemLink
                to="#"
                leftIcon={open ? <CollapseIcon /> : <ExpandIcon />}
                primaryText={open ? 'Згорнути' : 'Розгорнути'}
                onClick={() => setOpen(!open)}
                sx={{
                    '&.RaMenuItemLink-active': {
                        color: 'rgba(107, 114, 128, 0.7)'
                    }
                }}
            />
        </Box>
    );
};
