import React from 'react';
import { InputHelperText, useLocaleState, required as requiredValidate, useInput, TextInputProps } from 'react-admin';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled, Box } from '@mui/system';
import { Grid } from '@mui/material';
import { format } from 'date-fns';
import * as DateFnsLocales from 'date-fns/locale';
import type { Locale } from 'date-fns';
import cn from 'classnames';

import { LocaleMapping } from 'src/constants';
import { Theme } from 'src/Theme';

type LabelPosition = 'top' | 'left';

interface ShsDatePickerProps extends TextInputProps {
    label?: string;
    required?: boolean;
    labelPosition?: LabelPosition;
    removePaddingTop?: boolean;
    maxDate?: Date;
    minDate?: Date;
}

const styles = ({ labelPosition }: { labelPosition?: LabelPosition }) => ({
    '.MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: `${Theme.palette.primary.main}`
        },
        '&:focus fieldset': {
            border: `3px solid #75CACB`
        }
    },
    '&.MuiFormControl-root.invalid .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d32f2f'
    },
    '&.MuiFormControl-root.invalid .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2FA9A6 !important'
    },
    '& legend': {
        display: 'none'
    },
    '& input.MuiInputBase-input': {
        background: '#ffffff',
        padding: '11px 16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    },
    '& .error': {
        color: '#d32f2f',
        fontSize: '0.75rem',
        padding: 0
    }
});

const StyledDatePicker = styled(DatePicker)(styles);

const StyledErrors = styled('p')({
    color: '#d32f2f',
    fontSize: '0.75rem',
    padding: 0,
    margin: '4px 2px',
    minHeight: '12px'
});

const ShsDatePicker: React.FC<ShsDatePickerProps> = ({
    source,
    label,
    required,
    labelPosition,
    disabled,
    removePaddingTop = false,
    maxDate,
    minDate
}) => {
    const [value, setValue] = React.useState<Date | null>(null);

    const [locale] = useLocaleState();
    const currentLocale = LocaleMapping[locale] || locale || 'enUS';
    const locales = DateFnsLocales as Record<string, Locale>;
    const adapterLocale = locales[currentLocale];

    const isRequired = () => (required ? '*' : '');
    const validateFn = [...(required ? [requiredValidate()] : [])];

    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput({ source, isRequired: required, validate: validateFn });

    React.useEffect(() => {
        const defaultValue = field.value ? new Date(field.value) : null;
        setValue(defaultValue);
    }, []);

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
        field.onChange(newValue, format(newValue as Date, 'yyyy-MM-dd'));
    };

    const renderHelperText = (isTouched || isSubmitted) && invalid;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
            <Grid
                container
                flexDirection={labelPosition === 'top' ? 'column' : 'row'}
                gap={labelPosition === 'top' ? '10px' : 0}
                sx={{ paddingTop: labelPosition === 'top' || removePaddingTop ? 0 : '16px' }}>
                {label && (
                    <Grid item sm={labelPosition === 'top' ? 12 : 6} alignItems="center" display="flex" height="45px">
                        <label className="text_field__label">
                            {label || ''}
                            {isRequired()}
                        </label>
                    </Grid>
                )}
                <Grid item sm={labelPosition === 'top' ? 12 : label ? 6 : 12}>
                    <Box
                        flexDirection="column"
                        className={cn('text_field__container', labelPosition ? labelPosition : 'top')}>
                        <StyledDatePicker
                            value={value}
                            onChange={handleChange}
                            className={invalid ? 'invalid' : ''}
                            slotProps={{ textField: { placeholder: 'ДД.ММ.РРРР' } }}
                            disabled={disabled}
                            maxDate={maxDate}
                            minDate={minDate}
                        />
                        {renderHelperText ? (
                            <StyledErrors>
                                <InputHelperText touched={isTouched || isSubmitted} error={error?.message} />
                            </StyledErrors>
                        ) : null}
                    </Box>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default ShsDatePicker;
