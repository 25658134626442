import React from 'react';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import NoAccess from 'src/components/common/Errors/NoAccess';
import EmployeeList from './EmployeeList';
import { EmployeeCreate } from './EmployeeCreate';
import { AdminAndMethodologist } from 'src/constants/roles';
import EmployeeShow from './EmployeeShow';
import EmployeeEdit from './EmployeeEdit';
import { EmployeeListRolesGroup, EmployeeOnlyShowRolesGroup } from 'src/constants/employee/employee-roles-helper';

export default {
    list: (
        <WithRole roles={EmployeeListRolesGroup} orElse={<NoAccess />}>
            <EmployeeList />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, ...AdminAndMethodologist]} orElse={<NoAccess />}>
            <EmployeeCreate />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, ...AdminAndMethodologist]} orElse={<NoAccess />}>
            <EmployeeEdit />
        </WithRole>
    ),
    // EmployeeOnlyShowRolesGroup - suppose to see only own employee card
    show: (
        <WithRole roles={[...EmployeeOnlyShowRolesGroup, ...EmployeeListRolesGroup]} orElse={<NoAccess />}>
            <EmployeeShow />
        </WithRole>
    )
};
