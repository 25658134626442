import React, { FC } from 'react';
import { Create, RaRecord, SimpleForm, useCreatePath, useNotify, useRedirect } from 'react-admin';
import { Typography, Container } from '@mui/material';

import { UserRole } from 'src/auth/types';
import { ShsReturnButton } from 'src/components/common';
import WithRole from 'src/components/common/WithRole';
import ShsFormToolbar from 'src/components/common/ShsFormToolbar';
import { Resource } from 'src/constants';
import NoAccess from 'src/components/common/Errors/NoAccess';
import { LawInputsCard } from '../common/LawInputsCard';
import { palette } from '../../../Theme';

export const CreateLaw: FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const notify = useNotify();

    const handleCancelBtnClick = () => {
        redirect(createPath({ resource: 'laws', type: 'list' }));
    };

    const transform = (data: any) => ({
        ...data,
        name: data.name.trim()
    });

    return (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]} orElse={<NoAccess />}>
            <Container>
                <ShsReturnButton
                    to={createPath({ resource: 'laws', type: 'list' })}
                    label="Назад до законодавства"
                    sx={{ color: palette.primary.grayBluish }}
                />
                <Typography fontSize={40} fontWeight={700} sx={{ mb: 3 }}>
                    Додати НПА
                </Typography>

                <Create
                    transform={transform}
                    mutationOptions={{
                        onSuccess: (data: RaRecord) => {
                            notify('Нормативно-правовий акт успішно додано', {
                                type: 'success',
                                autoHideDuration: 2500
                            });
                            redirect('list', Resource.LAWS);
                        }
                    }}
                    sx={{ '.RaCreate-card': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                    <SimpleForm toolbar={false} mode="onBlur" sx={{ padding: 0 }}>
                        <LawInputsCard />
                        <ShsFormToolbar submitLabel="Додати НПА" handleCancel={handleCancelBtnClick} />
                    </SimpleForm>
                </Create>
            </Container>
        </WithRole>
    );
};
