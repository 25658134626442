import axiosApiInstance from 'src/api/axiosConfig';

export const useNotification = () => {
    const READ_NOTIFICATION = 'readNotifications';
    const NOTIFICATION_LENGTH = 'notificationLength';
    const NOTIFICATIONS = 'notifications';

    const getNotification = () => {
        axiosApiInstance.get(`notifications`).then((response) => {
            localStorage.setItem(NOTIFICATIONS, JSON.stringify(response.data));
        });

        const notifications = JSON.parse(localStorage.getItem(NOTIFICATIONS) || 'null');
        saveNotificationLengthToLocalStorage(String(notifications.length || 0));
        return notifications;
    };

    const saveNotificationLengthToLocalStorage = (notificationLength: string) => {
        localStorage.setItem(NOTIFICATION_LENGTH, notificationLength);
    };

    const getNotificationLengthFromLocalStorage = () => {
        const allNotifications = localStorage.getItem(NOTIFICATION_LENGTH) || 0;
        const notificationIds = localStorage.getItem(READ_NOTIFICATION);
        const readNotifications = notificationIds ? JSON.parse(notificationIds) : [];
        return +allNotifications - readNotifications.length;
    };

    const changeNotificationReadStatus = (id?: number) => {
        const readNotifications = localStorage.getItem(READ_NOTIFICATION);
        const data = readNotifications ? JSON.parse(readNotifications) : [];

        if (!data.find((x: number) => x === id)) {
            localStorage.setItem(READ_NOTIFICATION, JSON.stringify([...data, id]));
        } else {
            localStorage.setItem(READ_NOTIFICATION, JSON.stringify([...data.filter((x: number) => x !== id)]));
        }
    };

    const getLocalNotification = () => {
        const readNotifications = localStorage.getItem(READ_NOTIFICATION);

        return readNotifications ? JSON.parse(readNotifications) : [];
    };

    const clearLocalStorage = () => {
        localStorage.removeItem(READ_NOTIFICATION);
        localStorage.removeItem(NOTIFICATION_LENGTH);
        localStorage.removeItem(NOTIFICATIONS);
    };

    return {
        getNotification,
        getNotificationLengthFromLocalStorage,
        changeNotificationReadStatus,
        getLocalNotification,
        clearLocalStorage
    };
};
