import { AllUserRoles, UserRole } from 'src/auth/types';
import { AdminAndMethodologist, SystemAdminAndSecretaryRolesGroup } from '../roles';

// Used in menu items to define list views
export const EmployeeListRolesGroup: UserRole[] = [...SystemAdminAndSecretaryRolesGroup, ...AdminAndMethodologist];
const EmployeeNoEligibleRoles: UserRole[] = [UserRole.PARENT, ...EmployeeListRolesGroup];
// Used in menu items to define show views
export const EmployeeOnlyShowRolesGroup: UserRole[] = AllUserRoles.filter(
    (value: UserRole) => !EmployeeNoEligibleRoles.includes(value)
);
