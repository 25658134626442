import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Datagrid, EditButton, FunctionField, useAuthProvider, useListContext } from 'react-admin';
import { Box } from '@mui/material';

import { ReactComponent as EditIcon } from 'src/assets/pencil-alt.svg';
import { UnknownAvatar, StatusBadge, WithRole } from 'src/components/common';
import { ShsDateString } from 'src/components/common';
import type { Child } from 'src/components/Children/types';
import { getCurrentAgeInUAFormat, getYesNoFromBoolean, childrenUtils } from 'src/utils';
import { UserRole } from 'src/auth/types';
import { AdminGroup } from 'src/constants/roles';
import { NoValuePlaceholder, EducationLevelTableList } from 'src/constants/children/form';

interface ChildrenListProps {
    onTotalChanged?: (total: number) => void;
    isActiveTabSelected: boolean;
}

const Grid: React.FC<ChildrenListProps> = ({ onTotalChanged, isActiveTabSelected }) => {
    const { total } = useListContext();

    const authProvider = useAuthProvider();
    const showLimitedInformation = authProvider?.containsRoles(AdminGroup);

    useEffect(() => onTotalChanged?.(total), [total]);

    const getClassLabel = (educationLevel: string | null): string => {
        const educationLevelObj = EducationLevelTableList.find((level) => level.id === educationLevel);
        return educationLevelObj ? educationLevelObj.name : '-';
    };
    return (
        <Datagrid bulkActionButtons={false} rowClick={showLimitedInformation ? 'show' : false}>
            <FunctionField
                sortBy="lastName"
                label="Прізвище, ім’я та по батькові"
                className="bold"
                render={({ lastName, firstName, patronymic = '' }: Child) => (
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <UnknownAvatar
                            lastName={lastName}
                            firstName={firstName}
                            size="small"
                            style={{ marginRight: '12px' }}
                        />
                        <Box>{`${lastName} ${firstName} ${patronymic}`}</Box>
                    </Box>
                )}
            />

            <FunctionField
                sortBy="birthDate"
                label="Вік"
                render={({ birthDate }: Child) => getCurrentAgeInUAFormat(birthDate)}
            />

            <FunctionField
                label="Рівень освіти"
                render={({ visit: { educationLevelGroup } }: Child) => educationLevelGroup?.shortName}
            />

            <FunctionField
                sortBy="visits.educationLevel"
                label="Клас"
                render={({ visit: { educationLevel } }: Child) => getClassLabel(educationLevel)}
            />
            {isActiveTabSelected ? (
                <ShsDateString
                    sortBy="visits.servicesAgreementDate"
                    label="Початок навчання"
                    source="visit.servicesAgreementDate"
                />
            ) : (
                <ShsDateString
                    sortBy="visits.servicesEndDate"
                    label="Завершення навчання"
                    source="visit.servicesEndDate"
                />
            )}

            <FunctionField
                label="ООП"
                render={(data: Child) => getYesNoFromBoolean(get(data, 'visit.specialRequirements').enabled)}
            />

            {showLimitedInformation && (
                <FunctionField
                    sortBy="visits.disabilityPresent"
                    label="Інвалідність"
                    render={(data: Child) => getYesNoFromBoolean(get(data, 'visit.disabilityPresent'))}
                />
            )}

            <FunctionField
                sortBy="visits.internallyDisplaced"
                label="ВПО"
                render={(data: Child) => getYesNoFromBoolean(get(data, 'visit.internallyDisplaced'))}
            />

            <FunctionField
                sortBy="visits.academicHoursCount"
                label="Освітніх послуг"
                render={({ visit: { academicHoursCount } }: Child) => academicHoursCount ?? NoValuePlaceholder}
            />

            <FunctionField
                sortBy="visits.servicesEndDate"
                label="Статус"
                render={(data: Child) => (
                    <StatusBadge
                        isActive={childrenUtils.isChildEducationActive(
                            get(data, 'visit.servicesAgreementDate'),
                            get(data, 'visit.servicesEndDate')
                        )}
                    />
                )}
            />
            <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]}>
                <EditButton icon={<EditIcon />} label="" />
            </WithRole>
        </Datagrid>
    );
};

export default Grid;
