import React from 'react';
import { Filter } from 'react-admin';

import { EducationLevelGroup, FILTER_EMPTY_VALUE } from 'src/constants';
import { ShsSearchInput, ShsSelectInput, ShsAutoCompleteWithLoad } from 'src/components/common';
import { EducationLevelGroupFullName } from 'src/constants/children/form';

interface FiltersProps {
    showEducationCenterFilter?: boolean;
}

const filterDropdownEmptyText = 'Усі';

const educationLevelChoices: { id: EducationLevelGroup; name: string }[] = [
    { id: EducationLevelGroup.PRESCHOOL, name: `${EducationLevelGroupFullName[EducationLevelGroup.PRESCHOOL]}` },
    {
        id: EducationLevelGroup.JUNIOR_SCHOOL,
        name: `${EducationLevelGroupFullName[EducationLevelGroup.JUNIOR_SCHOOL]}`
    },
    { id: EducationLevelGroup.HIGH_SCHOOL, name: `${EducationLevelGroupFullName[EducationLevelGroup.HIGH_SCHOOL]}` }
];

const Filters: React.FC<FiltersProps> = ({ showEducationCenterFilter }) => (
    <Filter>
        <ShsSearchInput source="keyword" label="Знайти дитину" alwaysOn />
        {showEducationCenterFilter && (
            <ShsAutoCompleteWithLoad
                source="educationCenterId"
                pageUrl="centers"
                filters={{ isActive: true }}
                emptyValue={FILTER_EMPTY_VALUE}
                emptyText={filterDropdownEmptyText}
                alwaysOn
                keyField="id"
                textField="shortName"
                resultLimit={100}
                label="Освітній центр"
            />
        )}
        <ShsSelectInput
            // this source is used ONLY here in filters
            source="educationLevels"
            label="Рівень освіти"
            alwaysOn
            emptyValue={FILTER_EMPTY_VALUE}
            emptyText={filterDropdownEmptyText}
            choices={educationLevelChoices}
        />
    </Filter>
);

export default Filters;
