import React, { FC } from 'react';
import { Confirm, useCreatePath, useDelete, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { Typography, Stack, Box } from '@mui/material';

import { ShsButton } from '../../common';
import { interFontStyle, shsModalsStyles } from '../../../styles';
import CircleWarning from '../../../assets/CircleWarning';
import { Resource } from '../../../constants';
import { Theme } from 'src/Theme';

interface DeleteLawConfrimationProps {
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteLawConfirmation: FC<DeleteLawConfrimationProps> = ({ modalOpen, setModalOpen }) => {
    const record = useRecordContext();
    const createPath = useCreatePath();
    const redirect = useRedirect();
    const notify = useNotify();
    const [deleteOne] = useDelete();

    const handleCancelBtnClick = () => {
        setModalOpen(false);
    };

    const handleDeleteBtnClick = () => {
        deleteOne(
            Resource.LAWS,
            { id: record.id },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    notify('Видалення нормативно-правового акту пройшло успішно', {
                        type: 'success',
                        autoHideDuration: 2500
                    });
                    redirect(createPath({ resource: 'laws', type: 'list' }));
                },
                onError: (error: any) => {
                    notify(`Помилка: ${error?.message}`, {
                        type: 'error'
                    });
                }
            }
        );
        setModalOpen(false);
    };

    return (
        <Confirm
            isOpen={modalOpen}
            title=""
            confirmColor="warning"
            onConfirm={handleDeleteBtnClick}
            onClose={handleCancelBtnClick}
            sx={shsModalsStyles}
            content={
                <Stack alignItems="center">
                    <div className="shs-modal-header">
                        <Box mb={3} sx={{ textAlign: 'center' }}>
                            <CircleWarning />
                        </Box>
                        <Typography mb={2} mt={1} fontWeight="600" align="center" fontSize={'24px'}>
                            Видалення нормативно-правового акту
                        </Typography>
                    </div>
                    <div className="shs-modal-body">
                        <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                            {`Чи впевнені ви що хочете видалити нормативно-правовий акт «${record?.name}»?`}
                        </Typography>
                    </div>
                    <div className="shs-modal-footer">
                        <ShsButton
                            label="Скасувати"
                            styleType="outline"
                            onClick={handleCancelBtnClick}
                            sx={{
                                mr: 2,
                                borderColor: Theme.palette.primary.inputBorder,
                                color: Theme.palette.primary.midnightBlue
                            }}
                        />
                        <ShsButton label="Видалити" styleType="filled-danger" onClick={handleDeleteBtnClick} />
                    </div>
                </Stack>
            }
        />
    );
};
