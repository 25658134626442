import React, { useState } from 'react';
import { List, useAuthProvider } from 'react-admin';
import { Container, Tab, Tabs } from '@mui/material';

import { FILTER_EMPTY_VALUE } from 'src/constants';
import { listContainerStyles } from 'src/styles';
import { ShsTitle } from 'src/components/common';
import { UserRole } from 'src/auth/types';
import type { Child } from 'src/components/Children/types';
import { STATUS } from 'src/constants';
import ToolbarActions from './Toolbar';
import Filters from './Filters';
import Grid from './Grid';

const childTopFilterDefaultValues = {
    employmentType: FILTER_EMPTY_VALUE,
    educationCenterId: FILTER_EMPTY_VALUE,
    educationLevels: FILTER_EMPTY_VALUE
};

export const ChildrenList: React.FC = () => {
    const [activeTab, setActiveTab] = useState<STATUS.ACTIVE | STATUS.INACTIVE>(STATUS.ACTIVE);
    const [total, setTotal] = useState<number | undefined>(0);
    const authProvider = useAuthProvider();
    const showEducationCenterFilter = authProvider?.containsRoles([UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]);

    const currentDate = new Date().toISOString().slice(0, -14);
    return (
        <Container sx={listContainerStyles}>
            <ShsTitle title="Діти" found={total} />
            <List<Child>
                resource="children"
                exporter={false}
                empty={false}
                actions={<ToolbarActions />}
                filters={<Filters showEducationCenterFilter={showEducationCenterFilter} />}
                filterDefaultValues={childTopFilterDefaultValues}
                filter={
                    activeTab == STATUS.ACTIVE
                        ? { dateOfEndingGreater: currentDate }
                        : { dateOfEndingLess: currentDate }
                }>
                <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                    <Tab value={STATUS.ACTIVE} label="Зараз навчаються" />
                    <Tab value={STATUS.INACTIVE} label="Завершили навчання" />
                </Tabs>
                <Grid onTotalChanged={(total) => setTotal(total)} isActiveTabSelected={activeTab === STATUS.ACTIVE} />
            </List>
        </Container>
    );
};
